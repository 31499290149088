import React, { useCallback, useContext } from 'react';
import { GameContext } from '../gamecontext'; // Adjust the path if necessary
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "./game10.module.css";

const Game10 = () => {
  const { incrementScore, updateChoices } = useContext(GameContext);
  const navigate = useNavigate();

  const onGroupButtonClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onGroupButton1Click = useCallback(() => {
    incrementScore(1); // Assuming this is the weight for the first option
    updateChoices(1); // Update choices array
    navigate("/finalscore");
  }, [incrementScore, updateChoices, navigate]);
  
  const onGroupButton2Click = useCallback(() => {
    incrementScore(0); // Assuming this is the weight for the second option
    updateChoices(0); // Update choices array
    navigate("/finalscore");
  }, [incrementScore, updateChoices, navigate]);
  

  return (
    <div className={styles.game10}>
      <div className={styles.game10Child} />
      <h1 className={styles.chapter1} id="chapter">
        Chapter 10
      </h1>
      <h2 className={styles.groceryGrab} id="chap-title">
        Mount Movement
      </h2>
      <a className={styles.youStepIntoContainer} id="story">
        <span className={styles.youStepInto}>
        To conquer the towering Mount Movement, choices loom like shadows. 
        A smoky dragon offers a swift, solitary climb, shrouded in clouds. 
        Yet, the Caravan of Companionship extends an invitation to join them on shared giant eagles, soaring in unity. 
        </span>
        <span className={styles.youStepInto}>&nbsp;</span>
        <span className={styles.youStepInto}>
          <span className={styles.itsMrPlastic}>To ascend is to triumph:</span>
          <span className={styles.aSeasonedAdventurer}>
          </span>
        </span>
      </a>
      <img className={styles.sustainabilityqIcon} alt="" src="/undefined.png" />
      <Button
        className={styles.game10Item}
        variant="outline-primary"
        href="/"
        onClick={onGroupButtonClick}
      >
        BACK
      </Button>
      <button
        className={styles.rectangleParent}
        id="ans1"
        onClick={onGroupButton1Click}
      >
        <div className={styles.groupChild} />
        <div className={styles.sayHiToContainer}>
          <span className={styles.youStepInto} style={{ fontSize: "25px" }}>{`Join the Caravan of Companionship `}</span>
          <span className={styles.youStepInto} style={{ fontSize: "25px" }}>On their shared giant eagles 🦅</span>
        </div>
        <img className={styles.leaf1Icon} alt="" src="/undefined1.png" />
      </button>
      <button
        className={styles.rectangleGroup}
        id="ans2"
        onClick={onGroupButton2Click}
      >
        <div className={styles.groupItem} />
        <div className={styles.highFiveMrsReusable} style={{ fontSize: "25px" }}>
        Commandeer a smoky dragon to fly you solo 🐉
        </div>
        <img className={styles.leaf2Icon} alt="" src="/undefined2.png" />
      </button>
      <div className={styles.game10Inner} id="animation">
      <video controls autoPlay muted loop>
        <source src="/images/game10.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      </div>
    </div>
  );
};

export default Game10;
