import React, { useCallback, useContext } from 'react';
import { GameContext } from '../gamecontext'; // Adjust the path if necessary
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "./game8.module.css";

const Game8 = () => {
  const { incrementScore, updateChoices } = useContext(GameContext);
  const navigate = useNavigate();

  const onGroupButtonClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onGroupButton1Click = useCallback(() => {
    incrementScore(0); // Assuming this is the weight for the first option
    updateChoices(0); // Update choices array
    navigate("/game9");
  }, [incrementScore, updateChoices, navigate]);
  
  const onGroupButton2Click = useCallback(() => {
    incrementScore(1); // Assuming this is the weight for the second option
    updateChoices(1); // Update choices array
    navigate("/game9");
  }, [incrementScore, updateChoices, navigate]);
  

  return (
    <div className={styles.game8}>
      <div className={styles.game8Child} />
      <h1 className={styles.chapter1} id="chapter">
        Chapter 8
      </h1>
      <h2 className={styles.groceryGrab} id="chap-title">
        Recycling Realm
      </h2>
      <a className={styles.youStepIntoContainer} id="story">
        <span className={styles.youStepInto}>
        In the aftermath of a grand castle clean-up, you face the Land of Letting Go. 
        The Bottomless Pit swallows all, without thought or care. 
        But the Bins of Begin Again stand ready to give purpose to what was once discarded.
        </span>
        <span className={styles.youStepInto}>&nbsp;</span>
        <span className={styles.youStepInto}>
          <span>{`You have items that need to go to the Land of Letting Go.`}</span>
          <div></div>
          <span className={styles.itsMrPlastic}>In this moment, the destiny of your once-treasured possessions hangs in the balance:</span>
          <span className={styles.aSeasonedAdventurer}>
          </span>
        </span>
      </a>
      <img className={styles.sustainabilityqIcon} alt="" src="/undefined.png" />
      <Button
        className={styles.game8Item}
        variant="outline-primary"
        href="/"
        onClick={onGroupButtonClick}
      >
        BACK
      </Button>
      <button
        className={styles.rectangleParent}
        id="ans1"
        onClick={onGroupButton1Click}
      >
        <div className={styles.groupChild} />
        <div className={styles.sayHiToContainer}>
          <span className={styles.youStepInto} style={{ fontSize: "25px" }}>{`Toss them into the `}</span>
          <span className={styles.youStepInto} style={{ fontSize: "25px" }}>Bottomless Pit ⚫️</span>
        </div>
        <img className={styles.leaf1Icon} alt="" src="/undefined1.png" />
      </button>
      <button
        className={styles.rectangleGroup}
        id="ans2"
        onClick={onGroupButton2Click}
      >
        <div className={styles.groupItem} />
        <div className={styles.highFiveMrsReusable} style={{ fontSize: "25px" }}>
        Sort them into the Bins of Begin Again for recycling ♻️
        </div>
        <img className={styles.leaf2Icon} alt="" src="/undefined2.png" />
      </button>
      <div className={styles.game8Inner} id="animation">
      <video controls autoPlay muted loop>
        <source src="/images/game8.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      </div>
    </div>
  );
};

export default Game8;
