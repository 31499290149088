import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Landpage from "./pages/landpage";
import Game1 from "./pages/game1";
import Game2 from "./pages/game2";
import Game3 from "./pages/game3";
import Game4 from "./pages/game4";
import Game5 from "./pages/game5";
import Game6 from "./pages/game6";
import Game7 from "./pages/game7";
import Game8 from "./pages/game8";
import Game9 from "./pages/game9";
import Game10 from "./pages/game10";
import FinalScore from "./finalscore";
import AllCharacters from "./pages/allcharacters";
import { GameProvider, GameContext } from "./gamecontext";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "Landpage";
        metaDescription = "Landpage description.";
        break;
      case "/game1":
        title = "Game Chapter 1";
        metaDescription = "Game chapter 1 description.";
        break;
      case "/game2":
        title = "Game Chapter 2";
        metaDescription = "Game chapter 2 description.";
        break;
      case "/game3":
        title = "Game Chapter 3";
        metaDescription = "Game chapter 3 description.";
        break;
      case "/game4":
        title = "Game Chapter 4";
        metaDescription = "Game chapter 4 description.";
        break;  
      case "/game5":
        title = "Game Chapter 5";
        metaDescription = "Game chapter 5 description.";
        break; 
      case "/game6":
        title = "Game Chapter 6";
        metaDescription = "Game chapter 6 description.";
        break;
      case "/game7":
          title = "Game Chapter 7";
          metaDescription = "Game chapter 7 description.";
          break;
      case "/game8":
          title = "Game Chapter 8";
          metaDescription = "Game chapter 8 description.";
          break;
      case "/game9":
          title = "Game Chapter 9";
          metaDescription = "Game chapter 9 description.";
          break; 
      case "/game10":
          title = "Game Chapter 10";
          metaDescription = "Game chapter 10 description.";
          break; 
      case "/finalscore":
        title = "Final Score";
        metaDescription = "Final score description.";
        break;
      case "/allcharacters":
        title = "All Characters";
        metaDescription = "All Characters description.";
        break;
      case "/gamecontext":
        title = "Game Context";
        metaDescription = "Game Context.";
        break;
    }

    document.title = title;
    const metaDescriptionTag = document.querySelector(
      'head > meta[name="description"]'
    );
    if (metaDescriptionTag) {
      metaDescriptionTag.content = metaDescription;
    }
  }, [pathname]);

  return (
    <GameProvider>
      <Routes>
        <Route path="/" element={<Landpage />} />
        <Route path="/game1" element={<Game1 />} />
        <Route path="/game2" element={<Game2 />} />
        <Route path="/game3" element={<Game3 />} />
        <Route path="/game4" element={<Game4 />} />
        <Route path="/game5" element={<Game5 />} />
        <Route path="/game6" element={<Game6 />} />
        <Route path="/game7" element={<Game7 />} />
        <Route path="/game8" element={<Game8 />} />
        <Route path="/game9" element={<Game9 />} />
        <Route path="/game10" element={<Game10 />} />
        <Route path="/allcharacters" element={<AllCharacters />} />
        <Route path="/finalscore" element={<FinalScore />} />
      </Routes>
    </GameProvider>
  );
}

export default App;
