import React, { useCallback, useContext } from 'react';
import { GameContext } from '../gamecontext';
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "./game5.module.css";

const Game5 = () => {
  const { incrementScore, updateChoices } = useContext(GameContext);
  const navigate = useNavigate();

  const onGroupButtonClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onGroupButton1Click = useCallback(() => {
    incrementScore(1); // Assuming this is the weight for the first option
    updateChoices(1); // Update choices array
    navigate("/game6");
  }, [incrementScore, updateChoices, navigate]);
  
  const onGroupButton2Click = useCallback(() => {
    incrementScore(0); // Assuming this is the weight for the second option
    updateChoices(0); // Update choices array
    navigate("/game6");
  }, [incrementScore, updateChoices, navigate]);
  

  return (
    <div className={styles.game5}>
      <div className={styles.game5Child} />
      <h1 className={styles.chapter1} id="chapter">
        Chapter 5
      </h1>
      <h2 className={styles.groceryGrab} id="chap-title">
        Appliance Mountain
      </h2>
      <a className={styles.youStepIntoContainer} id="story">
        <span className={styles.youStepInto}>
            The towering Appliance Mountain, capped with snow and lined 
            with shelves of past and present inventions, looms before you.
        </span>
        <span className={styles.youStepInto}>&nbsp;</span>
        <span className={styles.youStepInto}>
          <span>{`Your old cooling chest, once a stalwart guardian against spoilage, has stopped working, compeling you to seek a new sentinel for your perishables. `}</span>
          <div></div>
          <span className={styles.itsMrPlastic}>In this realm of cold and innovation, do you:</span>
          <span className={styles.aSeasonedAdventurer}>
          </span>
        </span>
      </a>
      <img className={styles.sustainabilityqIcon} alt="" src="/undefined.png" />
      <Button
        className={styles.game5Item}
        variant="outline-primary"
        href="/"
        onClick={onGroupButtonClick}
      >
        BACK
      </Button>
      <button
        className={styles.rectangleParent}
        id="ans1"
        onClick={onGroupButton1Click}
      >
        <div className={styles.groupChild} />
        <div className={styles.sayHiToContainer}>
          <span className={styles.youStepInto} style={{ fontSize: "25px" }}>{`Trade with the Energy Elves `}</span>
          <span className={styles.youStepInto} style={{ fontSize: "25px" }}>for a chest that uses less power 🧝‍♀️</span>
        </div>
        <img className={styles.leaf1Icon} alt="" src="/undefined1.png" />
      </button>
      <button
        className={styles.rectangleGroup}
        id="ans2"
        onClick={onGroupButton2Click}
      >
        <div className={styles.groupItem} />
        <div className={styles.highFiveMrsReusable} style={{ fontSize: "25px" }}>
        Get the same model from the Ice Giants 🧊
        </div>
        <img className={styles.leaf2Icon} alt="" src="/undefined2.png" />
      </button>
      <div className={styles.game5Inner} id="animation">
      <video controls autoPlay muted loop>
        <source src="/images/game5.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div> 
    </div>
  );
};

export default Game5;
