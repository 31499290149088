import React, { useCallback, useContext } from 'react';
import { GameContext } from '../gamecontext'; // Adjust the path if necessary
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "./game2.module.css";

const Game2 = () => {
  const { incrementScore, updateChoices } = useContext(GameContext);
  const navigate = useNavigate();

  const onGame2ContainerClick = useCallback(() => {
    navigate("/game3");
  }, [navigate]);

  const onGroupButtonClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onGroupButton1Click = useCallback(() => {
    incrementScore(0); // Assuming this is the weight for the first option
    updateChoices(0); // Update choices array
    navigate("/game3");
  }, [incrementScore, updateChoices, navigate]);
  
  const onGroupButton2Click = useCallback(() => {
    incrementScore(1); // Assuming this is the weight for the second option
    updateChoices(1); // Update choices array
    navigate("/game3");
  }, [incrementScore, updateChoices, navigate]);

  return (
    <div className={styles.game2}>
      <div className={styles.game2Child} />
      <h1 className={styles.chapter1} id="chapter">
        Chapter 2
      </h1>
      <h2 className={styles.groceryGrab} id="chap-title">
        Cave of Light
      </h2>
      <a className={styles.youStepIntoContainer} id="story">
        <span className={styles.youStepInto}>
        Deep in the heart of the mystical Cave of Light, where shadows dance with beams, 
        a bulb flickers and fades. The Wax Wizard offers his candles, casting a warm but fleeting glow.   
        </span>
        <span className={styles.youStepInto}>
          <span>{` However, the Eco-Sprites have a gift: a glowing, energy-saving LED crystal. `}</span>
        </span>
        <span className={styles.youStepInto}>&nbsp;</span>
        <div></div>
        <span className={styles.itsMrPlastic}>
          <span>{`Your path forward is unclear:`}</span>
        </span>
      </a>
      <img className={styles.sustainabilityqIcon} alt="" src="/undefined.png" />
      <Button
        className={styles.game2Item}
        variant="outline-primary"
        href="/"
        onClick={onGroupButtonClick}
      >
        BACK
      </Button>
      <button
        className={styles.rectangleParent}
        id="ans1"
        onClick={onGroupButton1Click}
      >
        <div className={styles.groupChild} />
        <div className={styles.sayHiToContainer}>
          <span className={styles.youStepInto} style={{ fontSize: "25px" }}>{`Ignite a candle, `}</span>
          <span className={styles.youStepInto} style={{ fontSize: "25px" }}>a gift from the Wax Wizard himself 🕯</span>
        </div>
        <img className={styles.leaf1Icon} alt="" src="/undefined1.png" />
      </button>
      <button
        className={styles.rectangleGroup}
        id="ans2"
        onClick={onGroupButton2Click}
      >
        <div className={styles.groupItem} />
        <div className={styles.highFiveMrsReusable} style={{ fontSize: "25px" }}>
          Seek out a radiant, energy-efficient LED crystal 💡
        </div>
        <img className={styles.leaf2Icon} alt="" src="/undefined2.png" />
      </button>
    <div className={styles.game2Inner} id="animation">
      <video controls autoPlay muted loop>
        <source src="/images/game2.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
  );
};

export default Game2;
