import React, { useCallback } from 'react';
import { Button } from 'react-bootstrap'; // Import the Button component if you're using Bootstrap
import styles from "./allcharacters.module.css";

const AllCharacters = () => {
  const onGroupButtonClick = () => {
    window.location.href = '/';
  };
 
  const characters = [
    {
      name: 'The Worthless',
      description: "It seems like there's a significant gap between your actions and the potential impact they could have on the environment. At this stage, it appears you're not making efforts towards sustainability. Remember, even small changes can make a big difference. It's never too late to start taking steps towards a more sustainable lifestyle.",
      imageSrc: '/images/0.jpg',
    },
    {
      name: 'The Earth Destroyer',
      description: "Your current approach suggests a deep disconnection from environmental concerns. If everyone shared this outlook, the planet's future would be bleak. Consider this a wake-up call to reflect on how your choices affect the world around you. Small shifts in behavior can lead to significant positive impacts on our environment.",
      imageSrc: '/images/1.png',
    },
    {
      name: "The Climate Change Indifferent",
      description: "You seem to adopt a passive stance towards environmental issues, preferring adaptation over action. This approach can lead to missed opportunities to make meaningful changes. Engagement and proactive measures can be more effective in addressing climate challenges than mere adaptation.",
      imageSrc: "/images/2.png",
    },
    {
      name: "The Polluter",
      description: "Right now, your habits suggest a lack of prioritization for the environment, possibly due to a disconnection from the consequences of actions like littering. Rethinking these habits and considering their broader impact could be a step towards a more eco-friendly approach.",  
      imageSrc: "/images/3.png",
    },
    {
      name: "All You Can Eater",
      description: "You're at the beginning of your sustainability journey, showing some concern but struggling to make significant sacrifices. It's great that you're starting to care; remember, sustainability is about finding a balance that works for you while considering the planet's health.",
      imageSrc: "/images/4.png",
    },
    {
      name: "It's Not My Problem",
      description: "You seem aware of environmental issues but feel detached from them. This mindset might stem from a belief that individual actions don't make a difference, but they do. Small changes in daily life can contribute significantly to larger environmental solutions.",
      imageSrc: "/images/5.png",
    },
    {
      name: "I Care, But I Don't",
      description: "You speak to people about climate change, you engage in discussions about it, but you will not stop buying products or traveling by plane. You engage in conversations about sustainability and seem to understand its importance, yet there's a disconnect between your words and actions. Reflect on how aligning your behaviors with your beliefs can create a more cohesive and impactful approach to environmental care.",
      imageSrc: "/images/6.png",
    },
    {
      name: "The Activist",
      description: "You participate in manifestations, you make people aware of climate change, but you still contribute to it. Your activism and awareness-raising are commendable, yet there's room to align your personal habits with your advocacy. True change often starts with oneself; consider how your everyday choices can reflect the values you champion in public.",
      imageSrc: "/images/7.png",
    },
    {
      name: "The Fighter",
      description: "Actions are taken. You are the kind of person who acts. You put your efforts into changing things but still not at the maximum. You're actively making changes and taking steps towards sustainability. Your efforts show a commitment to improvement, though there's still some way to go to maximize your impact. Keep exploring ways to enhance your sustainable practices and inspire others.",
      imageSrc: "/images/8.png",      
    },
    {
      name: "The Planet Lover",
      description: "You love your planet and will do everything to save it, helping others realize that. You're still not 100% confident on the subject, but you're almost there. Your love and care for the planet are evident, and you're almost at the pinnacle of sustainable living. While there's still some uncertainty, your dedication is making a significant difference. Continue to learn and grow in your sustainability journey.",
      imageSrc: "/images/9.png",      
    },
    {
      name: "The EcoGOD",
      description: "The planet is still safe thanks to people like you. You engage in conversation, you are living a sustainable life, all your actions will be remembered. Well done, ecoGOD! Your dedication to sustainability is exemplary. You're not just talking the talk; you're walking the walk with every action you take. Your lifestyle is a beacon of inspiration, showing what's possible when one fully commits to environmental stewardship. You are a role model in the journey towards a sustainable future.",
      imageSrc: "/images/10.png",      
    }
  ]
  return (
    <div className={styles.allCharacters}>
      <Button
        className={styles.allCharactersButton}
        variant="outline-primary"
        onClick={onGroupButtonClick}
      >
        BACK
      </Button>
      <img className={styles.sustainabilityqIcon} alt="" src="/undefined.png" />
      <div className={styles.allCharactersList}>
        {characters.map((character, index) => (
          <div className={styles.character} key={index}>
            <img
              className={styles.characterImage}
              src={character.imageSrc}
              alt={`Character ${index + 1}`}
            />
            <div className={styles.characterInfo}>
              <h2 className={styles.characterName}>{character.name}</h2>
              <p className={styles.characterDescription}>{character.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllCharacters;