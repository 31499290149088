import React from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom"; // Import BrowserRouter
import App from "./App";
import { GameProvider } from './gamecontext'; // import the provider
import reportWebVitals from "./reportWebVitals";
import "./global.css";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <Router> {/* Wrap your components with Router */}
      <GameProvider>
        <App />
      </GameProvider>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
