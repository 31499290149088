import React, { useCallback, useContext } from 'react';
import { GameContext } from '../gamecontext'; // Adjust the path if necessary
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "./game9.module.css";

const Game9 = () => {
  const { incrementScore } = useContext(GameContext); // useContext must be used inside the component
  const navigate = useNavigate();

  const onGroupButtonClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onGroupButton1Click = useCallback(() => {
    incrementScore(1); // Assuming this is the weight for the first option
    navigate("/game10");
  }, [incrementScore, navigate]);
  
  const onGroupButton2Click = useCallback(() => {
    incrementScore(0); // Assuming this is the weight for the second option
    navigate("/game10");
  }, [incrementScore, navigate]);
  

  return (
    <div className={styles.game9}>
      <div className={styles.game9Child} />
      <h1 className={styles.chapter1} id="chapter">
        Chapter 9
      </h1>
      <h2 className={styles.groceryGrab} id="chap-title">
        Picnic in Plastic Plains
      </h2>
      <a className={styles.youStepIntoContainer} id="story">
        <span className={styles.youStepInto}>
        A feast awaits in the shimmering Plastic Plains. The land is strewn with easy, 
        gleaming utensils, yet they whisper of a lasting burden. 
        In your pack, the wizard-crafted spork from the Kingdom of Keep-Using awaits, a testament to reuse and resilience. 
        </span>
        <span className={styles.youStepInto}>&nbsp;</span>
        <span className={styles.youStepInto}>
          <span>{`Here, among the reflective sheen of synthetic grass, you are invited to dine. `}</span>
          <span className={styles.itsMrPlastic}>As you prepare to partake:</span>
          <span className={styles.aSeasonedAdventurer}>
          </span>
        </span>
      </a>
      <img className={styles.sustainabilityqIcon} alt="" src="/undefined.png" />
      <Button
        className={styles.game9Item}
        variant="outline-primary"
        href="/"
        onClick={onGroupButtonClick}
      >
        BACK
      </Button>
      <button
        className={styles.rectangleParent}
        id="ans1"
        onClick={onGroupButton1Click}
      >
        <div className={styles.groupChild} />
        <div className={styles.sayHiToContainer}>
          <span className={styles.youStepInto} style={{ fontSize: "25px" }}>{`Unveil your own `}</span>
          <span className={styles.youStepInto} style={{ fontSize: "25px" }}>Wizard Crafted Spork 🥄</span>
        </div>
        <img className={styles.leaf1Icon} alt="" src="/undefined1.png" />
      </button>
      <button
        className={styles.rectangleGroup}
        id="ans2"
        onClick={onGroupButton2Click}
      >
        <div className={styles.groupItem} />
        <div className={styles.highFiveMrsReusable} style={{ fontSize: "25px" }}>
        Use the Shiny Plastic Utensils 🍴
        </div>
        <img className={styles.leaf2Icon} alt="" src="/undefined2.png" />
      </button>
      <div className={styles.game9Inner} id="animation">
      <video controls autoPlay muted loop>
        <source src="/images/game9.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      </div>    </div>
  );
};

export default Game9;
