import React, { useCallback, useContext } from 'react';
import { GameContext } from '../gamecontext'; // Adjust the path if necessary
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "./game4.module.css";

const Game4 = () => {
  const { incrementScore, updateChoices } = useContext(GameContext);
  const navigate = useNavigate();

  const onGroupButtonClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onGroupButton1Click = useCallback(() => {
    incrementScore(1); // Assuming this is the weight for the first option
    updateChoices(1); // Update choices array
    navigate("/game5");
  }, [incrementScore, updateChoices, navigate]);
  
  const onGroupButton2Click = useCallback(() => {
    incrementScore(0); // Assuming this is the weight for the second option
    updateChoices(0); // Update choices array
    navigate("/game5");
  }, [incrementScore, updateChoices, navigate]);
  

  return (
    <div className={styles.game4}>
      <div className={styles.game4Child} />
      <h1 className={styles.chapter1} id="chapter">
        Chapter 4
      </h1>
      <h2 className={styles.groceryGrab} id="chap-title">
        Leftover Lagoon
      </h2>
      <a className={styles.youStepIntoContainer} id="story">
        <span className={styles.youStepInto}>
            In the aftermath of a feast fit for the forest spirits, 
            you find yourself holding some magical peels and mystical eggshells. 
            Around you, the Enchanted Forest hums with life, its floor a mosaic of moss and fallen petals.
        </span>
        <span className={styles.youStepInto}>&nbsp;</span>
        <span className={styles.youStepInto}>
          <span>{`The leftovers are a reminder of the feast's bounty, but they are also a burden.`}</span>
          <div></div>
          <span className={styles.itsMrPlastic}> Here, in this sacred clearing, you are faced with a choice of legacy:</span>
          <span className={styles.aSeasonedAdventurer}>
          </span>
        </span>
      </a>
      <img className={styles.sustainabilityqIcon} alt="" src="/undefined.png" />
      <Button
        className={styles.game4Item}
        variant="outline-primary"
        href="/"
        onClick={onGroupButtonClick}
      >
        BACK
      </Button>
      <button
        className={styles.rectangleParent}
        id="ans1"
        onClick={onGroupButton1Click}
      >
        <div className={styles.groupChild} />
        <div className={styles.sayHiToContainer}>
          <span className={styles.youStepInto} style={{ fontSize: "25px" }}>{`Offer them to the Compost Wizard for his Spell of Growth 🪄`}</span>
        </div>
        <img className={styles.leaf1Icon} alt="" src="/undefined1.png" />
      </button>
      <button
        className={styles.rectangleGroup}
        id="ans2"
        onClick={onGroupButton2Click}
      >
        <div className={styles.groupItem} />
        <div className={styles.highFiveMrsReusable} style={{ fontSize: "25px" }}>
        Bury the Remainers under the Trash Troll's Bridge 🧌
        </div>
        <img className={styles.leaf2Icon} alt="" src="/undefined2.png" />
      </button>
      <div className={styles.game4Inner} id="animation">
      <video controls autoPlay muted loop>
        <source src="/images/game4.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div> 
    </div>
  );
};

export default Game4;
