import { useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "./landpage.module.css";

const Landpage = () => {
  const navigate = useNavigate();

  const onGroupButtonClick = useCallback(() => {
    navigate("/game1");
  }, [navigate]);

  return (
    <div className={styles.landpage}>
      <div className={styles.landpageChild} />
      <h1 className={styles.welcomeAdventurer}>
        <p className={styles.welcome}>{`Welcome, `}</p>
        <p className={styles.welcome}>Adventurer!</p>
      </h1>
      <a className={styles.alrightLetsEmbarkContainer} id="intro">
        <p
          className={styles.welcome}
        >{`Alright, let’s embark on an Eco-Adventure Game! `}</p>
        <p className={styles.welcome}>&nbsp;</p>
        <p
          className={styles.welcome}
        >{`Get ready to make some exciting choices and see how eco-friendly you can be. `}</p>
        <p className={styles.welcome}>&nbsp;</p>
        <p className={styles.welcome}>
          <span>{`After each choice, you’ll earn leaves 🍃 if you choose the green option. At the end, we’ll count your leaves to see how `}</span>
          <span className={styles.superYouAre}>
            super you are at saving the planet!
          </span>
        </p>
      </a>
      <Button
        className={styles.landpageItem}
        variant="outline-primary"
        href="/game1"
        onClick={onGroupButtonClick}
      >
        START
      </Button>
      <img className={styles.sustainabilityqIcon} alt="" src="/undefined.png" />
      <img
        className={styles.studioPc232846ConvertedIcon}
        alt=""
        src="/intro-img@2x.png"
      />
    </div>
  );
};

export default Landpage;
