import React, { useCallback, useContext } from 'react';
import { GameContext } from '../gamecontext'; // Adjust the path if necessary
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "./game7.module.css";

const Game7 = () => {
  const { incrementScore, updateChoices } = useContext(GameContext);
  const navigate = useNavigate();

  const onGroupButtonClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onGroupButton1Click = useCallback(() => {
    incrementScore(0); // Assuming this is the weight for the first option
    updateChoices(0); // Update choices array
    navigate("/game8");
  }, [incrementScore, updateChoices, navigate]);
  
  const onGroupButton2Click = useCallback(() => {
    incrementScore(1); // Assuming this is the weight for the second option
    updateChoices(1); // Update choices array
    navigate("/game8");
  }, [incrementScore, updateChoices, navigate]);
  

  return (
    <div className={styles.game7}>
      <div className={styles.game7Child} />
      <h1 className={styles.chapter1} id="chapter">
        Chapter 7
      </h1>
      <h2 className={styles.groceryGrab} id="chap-title">
        Veggie Village Feast
      </h2>
      <a className={styles.youStepIntoContainer} id="story">
        <span className={styles.youStepInto}>
            The charming chime of the Veggie Village bell heralds a spread that could 
            satisfy the most ravenous of sprites or the hungriest of hobgoblins.  
        </span>
        <span className={styles.youStepInto}>&nbsp;</span>
        <span className={styles.youStepInto}>
          <span>{`The scent of fresh harvest wafts through the air, mingling with the joyful sounds of the village.`}</span>
          <div></div>
          <span className={styles.itsMrPlastic}>Amidst this celebration of plenty, you are beckoned to choose:</span>
          <span className={styles.aSeasonedAdventurer}>
          </span>
        </span>
      </a>
      <img className={styles.sustainabilityqIcon} alt="" src="/undefined.png" />
      <Button
        className={styles.game7Item}
        variant="outline-primary"
        href="/"
        onClick={onGroupButtonClick}
      >
        BACK
      </Button>
      <button
        className={styles.rectangleParent}
        id="ans1"
        onClick={onGroupButton1Click}
      >
        <div className={styles.groupChild} />
        <div className={styles.sayHiToContainer}>
          <span className={styles.youStepInto} style={{ fontSize: "25px" }}>{`Partake in a meal carved `}</span>
          <span className={styles.youStepInto} style={{ fontSize: "25px" }}>from the Butcher’s Block 🥩</span>
        </div>
        <img className={styles.leaf1Icon} alt="" src="/undefined1.png" />
      </button>
      <button
        className={styles.rectangleGroup}
        id="ans2"
        onClick={onGroupButton2Click}
      >
        <div className={styles.groupItem} />
        <div className={styles.highFiveMrsReusable} style={{ fontSize: "25px" }}>
        Embrace the harvest of the Plant-Powered Patch 🥕
        </div>
        <img className={styles.leaf2Icon} alt="" src="/undefined2.png" />
      </button>
      <div className={styles.game7Inner} id="animation">
      <video controls autoPlay muted loop>
        <source src="/images/game7.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      </div>     
    </div>
  );
};

export default Game7;
