import React, { createContext, useState, useCallback } from 'react';

export const GameContext = createContext();

export const GameProvider = ({ children }) => {
  const [score, setScore] = useState(0);
  const [choices, setChoices] = useState([]);

  const incrementScore = useCallback((weight) => {
    setScore((prevScore) => prevScore + weight);
  }, []);

  const updateChoices = useCallback((choice) => {
    setChoices((prevChoices) => [...prevChoices, choice]);
  }, []);

  // Include setChoices in the context value
  const contextValue = {
    score,
    incrementScore,
    choices,
    updateChoices, // Add updateChoices to the context value
  };

  return (
    <GameContext.Provider value={contextValue}>
      {children}
    </GameContext.Provider>
  );
};
